<!-- 自提详情 -->
<template>
  <div>
    <back />
    <div class="det" v-loading="loading">
      <div class="detail">
        <h4 class="title">基本信息：</h4>
        <el-row class="row">
          <el-col :span="5">
            <div class="col order-code">缴费单号：{{ basicInfo.orderNum }}</div>
            <div class="col">缴费时间：{{ basicInfo.payTime || '-' }}</div>
          </el-col>
          <el-col :span="5">
            <div class="col">结算时间：{{ basicInfo.confirmTime }}</div>
          </el-col>
          <el-col :span="5">
            <div class="col">结算周期：{{ `${basicInfo.startDate}-${basicInfo.endDate}` }}</div>
          </el-col>
          <el-col :span="4">
            <div class="col">欠款金额：{{ Number(basicInfo.orderAmount).toFixed(2) }}元</div>
          </el-col>
          <el-col :span="4">
            <div class="col order-code">缴费状态：
              <span v-if="basicInfo.status == 1">已缴费</span>
              <span v-if="basicInfo.status == 0">未缴费</span>
            </div>
          </el-col>

        </el-row>

      </div>
      <!-- 商品信息 -->
      <div class="detail">
        <h4 class="title">商品信息：</h4>
        <!-- 表格 -->
        <div class="globle_table tableBox">
          <el-table :data="tableData" style="width: 100%" max-height="540">
            <el-table-column label="序号" min-width="60" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
              </template>
            </el-table-column>
            <el-table-column min-width="120" show-overflow-tooltip prop="orderNum" label="订单号"></el-table-column>
            <el-table-column min-width="100" show-overflow-tooltip prop="orderSource" label="订单类型">
              <template slot-scope="scope">
                <span v-if="scope.row.orderSource == 1">线下订单</span>
                <span v-if="scope.row.orderSource == 2">线上订单</span>
                <span v-if="scope.row.orderSource == 3">会员充值</span>
                <span v-if="scope.row.orderSource == 4">运费加价</span>
              </template>
            </el-table-column>
            <el-table-column min-width="100" show-overflow-tooltip prop="orderType" label="结算类型">
              <template slot-scope="scope">
                <span v-if="scope.row.orderType == 1">销售</span>
                <span v-if="scope.row.orderType == 2">退货</span>
                <span v-if="scope.row.orderType == 3">充值</span>
              </template>
            </el-table-column>
            <el-table-column min-width="100" prop="payType" label="支付方式" show-overflow-tooltip>
              <template slot-scope="scope">
                <div>
                  {{ getOrderTypeText(scope.row.payType) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="110" show-overflow-tooltip prop="payAmount" label="金额(元)"></el-table-column>
            <el-table-column min-width="110" show-overflow-tooltip prop="supplyAmount" label="进货金额(元)"
              width="140"></el-table-column>
            <el-table-column min-width="110" show-overflow-tooltip prop="deliveryAmount" label="骑手配送费(元)"
              width="140"></el-table-column>
            <!-- <el-table-column min-width="110" show-overflow-tooltip prop="shopAddFreight" label="运费加价(元)"
              width="140"></el-table-column> -->
            <el-table-column min-width="110" show-overflow-tooltip prop="settlementAmount" label="结算金额(元)"
              width="140"></el-table-column>
            <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="时间"
              width="180"></el-table-column>
            <!-- <el-table-column min-width="90" show-overflow-tooltip prop="billStatus" label="是否结算">
              <template slot-scope="scope">
                <span v-if="scope.row.billStatus == 0">否</span>
                <span v-if="scope.row.billStatus == 1">是</span>
                <span v-if="scope.row.billStatus == -1">无需结算</span>
              </template>
            </el-table-column> -->
            <el-table-column label="操作" header-align="center" align="center" width="200" fixed="right">
              <template slot-scope="scope">
                <el-button size="small" class="btn" type="primary" plain
                  @click="handleCommand(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
          layout="prev, pager, next, jumper, sizes, total" :total="totalCount"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      basicInfo: {},//基本信息
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
    }
  },

  created() {
    this.getRequest()
    this.getSettlementInfoList()
  },
  methods: {
    // 请求详情信息
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.selectSettlementArrearsPayDet, {
        params: {
          id: this.$route.query.id,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.basicInfo = result
          this.tableData = result.selectOnlineOrdersProductVoList
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })
    },
    // 获取订单信息列表
    getSettlementInfoList() {
      this.loading = true
      let params = {
        billStatus: null, // 分账状态
        orderNum: null, // 搜索订单号
        shopId: this.$store.state.userInfo.shopId, //shopId
        startTime: this.$route.query.startDate, //周期开始时间
        endTime: this.$route.query.endDate, //周期开始时间
        settlementType: 1,
        arrearsType: 1,//后端要求
        orderSource: 2,// 订单类型 后端要求
        orderType: 1,// 选择结算类型  后端要求
        payType: 1,// 支付方式  后端要求
        page: this.currentPage,
        pageSize: this.pageSize,
      }
      this.$axios
        .get(this.$api.getSettlementInfoList, { params })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 支付类型
    getOrderTypeText(status) {
      switch (String(status)) {
        case "0":
          return "现金支付"
        case "1":
          return "线上支付"
        case "3":
          return "会员卡支付"
        default:
          return
      }
    },
    // 查看按钮
    handleCommand(row) {
      this.$router.push({ name: "orderDet", query: { id: row.id } })
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getSettlementInfoList() //请求数据
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      this.tableData = []
      this.currentPage = val
      this.getSettlementInfoList()
    },
  },
}
</script>

<style scoped lang='scss'>
.det {
  padding: 0 30px;

  .detail {
    padding-top: 20px;
    border-bottom: 1px solid rgba(3, 16, 14, 0.06);

    .title {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .row {
      color: #707070;
      // font-size: 16px;

      .col {
        margin-bottom: 20px;
        // margin-right: 10px;
        font-size: 16px;
      }

      .order-code {
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .pagination {
      margin-bottom: 30px;
    }

  }



}
</style>